<template>
    <div class="icon_box">
        <img
            @click="goOrderList"
            v-if="current=='1'"
            :src="require('@img/btn_floating.png')"
            alt="">
        <img
            v-if="current=='2'"
            :src="require('@img/ask.png')"
            alt="">
    </div>
</template>
<script>
export default {
    name: 'Right',
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        current: {
            type: String,
            default: '2', // 1 购药订单 2 咨询问诊
        },
    },
    methods: {
        goOrderList() {
            this.$router.push({
                name: 'order',
            });
        },

    },
};
</script>
<style lang="scss" scoped>
.icon_box {
    position: fixed;
    right: 0;
    bottom: 104px;
    img {
        width: auto;
        height: 64px;
    }
}
</style>
