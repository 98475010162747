<template>
    <div>
        <!-- <div class="search_outer"> -->
        <div :class="{'search_outer': styles === 1, 'new_style2': styles === 2}">
            <slot name="city" />
            <div
                :style="customStyle"
                @click="toSearch"
                class="search_static">
                <img
                    :src="require('@img/icon_search_grey.png')"
                    class="icon_search ml12 mr8"
                    alt="">
                <span class="palceholder">{{ placeholder }}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        customStyle: {
            type: Object,
            default: () => ({
                background: '#f5f7fa',
            }),
        },
        placeholder: {
            type: String,
            default: '搜索药品名称',
        },
        styles: {
            type: Number,
            default: 1,
        },
    },
    methods: {
        toSearch() {
            this.$emit('toSearch');
        },
    },
};
</script>
<style lang="scss" scoped>
.search_outer {
    display: flex;

    .search_static {
        flex: 1;
        height: 28px;
        line-height: 28px;
        border-radius: 100px;

        .icon_search {
            margin-top: -1px;
            vertical-align: middle;
        }

        .palceholder {
            font-weight: 400;
            font-size: 14px;
            color: #babfc9;
        }
    }
}

.new_style2 {
    display: flex;
    padding: 8px 18px;
    background: #fff;
    box-shadow: 0 0 8px 0 rgba(60, 107, 240, 0.1);

    /deep/ .city_name {
        color: #62636c;
    }

    .search_static {
        flex: 1;
        height: 28px;
        line-height: 28px;
        background: #f5f7fa;
        border-radius: 100px;

        .icon_search {
            margin-top: -1px;
            vertical-align: middle;
        }

        .palceholder {
            font-weight: 400;
            font-size: 14px;
            color: #babfc9;
        }
    }
}
</style>
