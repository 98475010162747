<template>
  <div class="container">
    <div v-if="!cityInfo.cityCode">
      <StaticSearch :styles="2" @toSearch="toSearch">
        <template v-slot:city>
          <CityPosition :icon="{ arrow: 'black' }" />
        </template>
      </StaticSearch>
      <EmptyData :type="1" />
    </div>
    <div v-else>
      <div v-if="!storeList.length">
        <StaticSearch @toSearch="toSearch" :styles="2">
          <template v-slot:city>
            <CityPosition :icon="{ arrow: 'black' }" />
          </template>
        </StaticSearch>
        <EmptyData :type="2" />
      </div>
      <div v-else>
        <div class="header">
          <StaticSearch @toSearch="toSearch" class="mt16 mb16 pl18 pr18 search">
            <template v-slot:city>
              <CityPosition />
            </template>
          </StaticSearch>
          <EmptyData tip="您当前所在城市暂无药品信息，请手动选择其他城市" />
        </div>
        <List
          v-model="loading"
          :finished="finished"
          @load="onLoad"
          finished-text="到底啦～"
        >
          <div v-if="swipeData.length" class="pl12 pr12">
            <div class="classify">
              <Swipe
                :show-indicators="false"
                @change="changeIndicator"
                class="my_swipe"
              >
                <Swipe-item v-for="(item, index) in swipeData" :key="index">
                  <div class="cell_wrapper">
                    <div
                      v-for="cell in item"
                      :key="cell.id"
                      @click="toSearch(cell)"
                      class="cell"
                    >
                      <div class="mb6">
                        <img v-lazy="cell.imageUrl" class="img" alt="" />
                      </div>
                      <p class="title">
                        {{ cell.tagName }}
                      </p>
                    </div>
                  </div>
                </Swipe-item>
                <template v-if="indicatorTotal > 1" #indicator>
                  <div class="indicator_wrapper">
                    <span
                      v-for="i in indicatorTotal"
                      :key="i"
                      :class="{ active: current === i - 1 }"
                      class="indicator"
                    />
                  </div>
                </template>
              </Swipe>
            </div>
          </div>

          <div class="pl12 pr12">
            <div
              v-for="store in storeList"
              :key="store.id"
              @click="goStore(store)"
              class="card mt8"
            >
              <div class="store">
                <div>
                  <img
                    v-lazy="
                      store.pharmacyInfo.pictureUrl || require('@img/store.png')
                    "
                    class="img"
                    alt=""
                  />
                </div>
                <div class="info">
                  <p v-ellipsis="1" class="title">
                    {{ store.pharmacyInfo.fullName }}
                  </p>
                  <div class="location">
                    <p v-ellipsis="2" class="address">
                      {{
                        store.pharmacyInfo.provinceStr +
                        store.pharmacyInfo.cityStr +
                        store.pharmacyInfo.districtStr +
                        store.pharmacyInfo.address
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="drugs">
                <div
                  v-for="drug in store.goodsDetailCO"
                  :key="drug.goodsId"
                  class="drug"
                >
                  <div class="img_wrapper">
                    <div class="img">
                      <img
                        v-lazy="
                          drug.productImageUrl
                            ? drug.productImageUrl[0]
                            : require('@img/default.png')
                        "
                        alt=""
                      />
                      <div v-if="drug.prescription === 1" class="tag">
                        处方药
                      </div>
                    </div>
                  </div>
                  <p v-ellipsis="2" class="info">
                    {{ `${drug.productName}（${drug.usualName}）` }}
                  </p>
                  <p class="price">¥ {{ drug.salePrice | formatMoney }}</p>
                  <div v-if="drug.memberPrice" class="member-price mb6">
                    <MemberPrice :price="drug.memberPrice" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </List>
      </div>
      <rightIcon :current="'1'" />
    </div>
  </div>
</template>
<script>
import {
  Swipe, SwipeItem, List, Toast,
} from 'vant';
import StaticSearch from '@/components/static-search';
import MemberPrice from '@/components/member-price';
import rightIcon from '@/components/right/index';
import CityPosition from '@/components/city-position';
import EmptyData from '@/components/empty-data';
import api from '@/api';
import WxTool from '@/utils/wx-tool';
import { mapState } from 'vuex';
import Configs from '@/config';

export default {
  components: {
    List,
    Swipe,
    SwipeItem,
    StaticSearch,
    rightIcon,
    CityPosition,
    EmptyData,
    MemberPrice,
  },
  filters: {
    formatMoney (num) {
      return num.toFixed(2);
    },
  },
  data () {
    return {
      swipeData: [],
      current: 0,
      indicatorTotal: 0,
      storeList: [],
      loading: false,
      finished: false,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
    };
  },
  computed: {
    ...mapState({
      cityInfo: (store) => store.city.cityInfo,
    }),
    channelCode () {
      return localStorage.getItem('channelCode')
    }
  },
  watch: {
    'cityInfo.cityCode': {
      handler (n, o) {
        if (!o) {
          this.getMerchantList();
          this.getTagList();
        }
      },
    },
  },
  created () {
    const instance = WxTool.getInstance();
    instance.onShare();

    // if (this.cityInfo.cityCode) {
    this.getMerchantList();
    this.getTagList();
    // }
  },
  methods: {
    onLoad () {
      // 暂时数据较少，先不分页了
      // if (this.totalPage && this.pageNumber < this.totalPage) {
      //     this.pageNumber += 1;
      //     this.getMerchantList();
      // }
    },
    changeIndicator (index) {
      this.current = index;
    },
    toSearch (param) {
      // 如果定位失败，不能前往搜索
      if (!this.cityInfo.cityCode) {
        Toast('请选择城市');
        return;
      }
      // 有param是从标签跳转，否则从搜索框跳转
      if (!param) {
        if (!this.storeList.length) {
          // Toast('当前所在城市暂无药品信息');
          Toast('您当前所在城市暂无药品信息，请手动选择其他城市');
          return;
        }
      }
      const params = {
        type: param ? 'tag' : 'text',
      };
      if (param) {
        params.title = param.tagName;
        params.tid = param.tagId;
      }
      this.$router.push({
        name: 'search-result',
        params,
      });
    },
    getMerchantList () {
      const { cityId, provincesId } = this.$store.state.city.cityInfo;
      const param = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        cityId,
        provincesId,
      };
      if (this.channelCode === "PAK") { // 派安康来源
        param.saleChannel = Configs.channelCode[this.channelCode]
      }
      api.getMerchantList(param).then((res) => {
        const { list = [], totalPage = 0 } = res.data;
        this.storeList = this.storeList.concat(list);
        this.loading = false;
        this.totalPage = totalPage;
        if (this.pageNumber >= this.totalPage) {
          this.finished = true;
        }
      });
    },
    getTagList () {
      const { cityId, provincesId } = this.$store.state.city.cityInfo;

      const params = { cityId, provincesId }
      if (this.channelCode && this.channelCode === "PAK") { // 派安康来源
        params.saleChannel = Configs.channelCode[this.channelCode]
      }
      api.getTagList(params).then((res) => {
        const { data: list = [] } = res;
        const swipeItems = 10;
        this.indicatorTotal = Math.ceil(list.length / swipeItems);
        const array = [];
        for (let i = 0, len = this.indicatorTotal; i < len; i++) {
          array[i] = [];
        }
        list.forEach((item, index) => {
          array[Math.floor(index / swipeItems)].push(item);
        });
        this.swipeData = array;
      });
    },
    goStore (store) {
      this.$router.push({
        name: 'store',
        params: {
          merchantCode: store.pharmacyInfo.groupCode,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@styles/variables.scss';
.container {
  height: 100%;
  overflow: hidden;

  /deep/ .van-list__finished-text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
    width: auto;
    font-size: 11px;
    font-family: $font-family-PF-R;
    font-weight: 400;
    color: #babfc9;
    line-height: 16px;

    &::before,
    &::after {
      content: '';
      display: inline-block;
      margin: 0 8px;
      width: 40px;
      height: 1px;
      background: #dcdfe4;
      color: red;
    }
  }
}
.header {
  height: 137px;
  background: url('~@img/home/bg.png') no-repeat;
  background-size: contain;
  overflow: hidden;

  .search {
    height: 20px;
    line-height: 20px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
.van-list {
  margin-top: -77px;
  height: calc(100vh - 60px);
  overflow: scroll;
}
.classify {
  width: 351px;
  height: 183px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(54, 121, 240, 0.1);
  border-radius: 6px;
}
.my_swipe {
  .van-swipe-item {
    min-height: 183px;
  }

  .indicator_wrapper {
    position: absolute;
    bottom: 11px;
    width: 100%;
    text-align: center;

    .indicator {
      display: inline-block;
      width: 20px;
      height: 2px;
      background: #e4e4e4;
      border-radius: 1px;

      &.active {
        background: #f76d32;
      }
    }
  }
}
.cell_wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;

  .cell {
    margin-top: 12px;
    width: 64px;
    text-align: center;

    &:nth-child(5n) {
      margin-right: 0;
    }

    .img {
      width: 44px;
      height: 44px;
    }

    .title {
      font-size: 12px;
      font-weight: 400;
      color: $--color-title;
      line-height: 1;
    }
  }
}
.card {
  width: 100%;
  // height: 252px;
  padding-bottom: 8px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(54, 121, 240, 0.1);
  border-radius: 6px;

  .store {
    display: flex;
    padding: 16px 16px 0;

    .img {
      width: 65px;
      height: 65px;
      border-radius: 4px;
    }

    .info {
      padding-left: 8px;

      .title {
        margin-bottom: 4px;
        font-size: 16px;
        font-family: $font-family-PF-S;
        font-weight: 600;
        color: $--color-title;
        line-height: 22px;
      }

      .location {
        display: flex;

        .address {
          flex: 1;
          font-size: 12px;
          font-family: $font-family-PF-R;
          font-weight: 400;
          color: $--color-title;
          line-height: 17px;
        }

        &::before {
          content: '';
          margin-right: 4px;
          width: 16px;
          height: 16px;
          background: url('~@img/icon_location.png') no-repeat;
          background-size: cover;
        }
      }
    }
  }

  .drugs {
    display: flex;
    padding: 0 8px;

    .drug {
      padding: 0 8px;
      // flex: 1;
      width: 108px;

      .img_wrapper {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 11px;

        .img {
          position: relative;
          margin: 0 auto;
          // width: 68px;
          // height: 68px;
          width: 100%;
          height: 92px;
          text-align: center;
          background: #edf1f8;

          img {
            width: 100%;
            height: 100%;
            // max-height: 68px;
            max-width: 100%;
            border-radius: 4px;
          }

          .tag {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 44px;
            height: 16px;
            line-height: 16px;
            color: #fff;
            text-align: center;
            background: url('~@img/tag_bg.png') no-repeat;
            background-size: contain;
          }
        }
      }

      .info {
        margin-bottom: 6px;
        min-height: 34px;
        font-size: 12px;
        font-weight: 500;
        color: $--color-title;
        line-height: 17px;
      }

      .price {
        margin-bottom: 8px;
        font-size: 14px;
        font-family: $font-family-AV-M;
        font-weight: 500;
        color: #e94947;
        line-height: 19px;
      }
    }
  }
}
</style>
